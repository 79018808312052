import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import {mapLineBreak} from '../../../Utils/TextUtil';
import * as AppActions from '../../../AppActions';

function AboutSection(props) {
  const {landing} = props;
  const [dimension] = useOutlet('dimension');
  const mobile = dimension.rwd === 'mobile';

  return (
    <Wrapper mobile={mobile}>
      <AboutItem item={landing.type3_layout.about_section} mobile={mobile} />
    </Wrapper>
  );
}

function AboutItem(props) {
  const {item, mobile} = props;

  return (
    <ItemWrapper bgImg={item.image} mobile={mobile}>
      <div className="bgImg" />
      <h3>{item.title}</h3>
      <div
        className="text"
        dangerouslySetInnerHTML={{__html: mapLineBreak(item.description)}}
      />
      <button onClick={() => AppActions.navigate(item.path)}>Read More</button>
    </ItemWrapper>
  );
}

const Wrapper = styled.div`
  max-width: var(--contentMaxWidth);
  width: 100%;
  margin: 0 auto;

  display: flex;
  flex-direciton: column;
  justify-content: center;
  align-items: center;
  padding: ${(props) => (props.mobile ? '0px' : '0px 40px')};
`;

const ItemWrapper = styled.div`
  max-width: 820px;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > div.bgImg {
    margin-bottom: 50px;
    width: 100%;
    height: ${(props) =>
      props.mobile ? `calc(100vw / 1.6)` : `calc(820px / 1.6)`};

    background-image: url(${(props) => props.bgImg});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  & > h3 {
    margin-bottom: 20px;
    font-size: 1.2rem;
    text-align: center;
  }

  & > div.text {
    margin-bottom: 40px;
    font-size: 0.85rem;
    text-align: center;
  }

  & > button {
    cursor: pointer;
    border: 0px;
    width: 200px;
    height: 40px;

    background-color: var(--primaryColor);
    color: #ffffff;

    &:hover {
      border: 1px solid var(--primaryColor);
      background-color: #ffffff;
      color: var(--primaryColor);
    }
  }
`;

export default AboutSection;
