import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import {ArrowIosBackOutline} from '@styled-icons/evaicons-outline/ArrowIosBackOutline';
import {ArrowIosForwardOutline} from '@styled-icons/evaicons-outline/ArrowIosForwardOutline';
import * as AppActions from '../../../AppActions';
import Carousel, {SWIPE_STATE} from '../../../Components/Carousel';

function HeroBannerSection(props) {
  const {landing} = props;
  const [dimension] = useOutlet('dimension');
  const mobile = dimension.rwd === 'mobile';

  return (
    <Wrapper>
      <div className="container">
        {mobile ? (
          //size 1:1
          <Carousel
            width={dimension.innerWidth}
            height={dimension.innerWidth}
            data={landing.type3_layout.hero_banner_section.images_mobile || []}
            renderPrev={({currIdx, goToIndex}) => {
              return (
                <ActionButton
                  direction="left"
                  size={26}
                  onClick={() => goToIndex(currIdx - 1)}>
                  <ArrowIosBackOutline color="#ffffff" style={{fontSize: 24}} />
                </ActionButton>
              );
            }}
            renderNext={({currIdx, goToIndex}) => {
              return (
                <ActionButton
                  direction="right"
                  size={26}
                  onClick={() => goToIndex(currIdx + 1)}>
                  <ArrowIosForwardOutline
                    color="#ffffff"
                    style={{fontSize: 24}}
                  />
                </ActionButton>
              );
            }}
            disableSwipe={true}
            renderItem={({item, dimension, swipeStateRef}) => (
              <SlideItem
                item={item}
                dimension={dimension}
                swipeStateRef={swipeStateRef}
              />
            )}
            renderDots={({currIdx, goToIndex}) => (
              <Dots
                mobile={mobile}
                currIdx={currIdx}
                goToIndex={goToIndex}
                dataLength={
                  (landing.type3_layout.hero_banner_section.images_mobile || [])
                    .length
                }
              />
            )}
          />
        ) : (
          // size 16:9
          <Carousel
            width={dimension.innerWidth - 80}
            height={((dimension.innerWidth - 80) / 16) * 9}
            data={landing.type3_layout.hero_banner_section.images || []}
            renderPrev={({currIdx, goToIndex}) => {
              return (
                <ActionButton
                  direction="left"
                  size={40}
                  onClick={() => goToIndex(currIdx - 1)}>
                  <ArrowIosBackOutline color="#ffffff" />
                </ActionButton>
              );
            }}
            renderNext={({currIdx, goToIndex}) => {
              return (
                <ActionButton
                  direction="right"
                  size={40}
                  onClick={() => goToIndex(currIdx + 1)}>
                  <ArrowIosForwardOutline color="#ffffff" />
                </ActionButton>
              );
            }}
            disableSwipe={true}
            renderItem={({item, dimension, swipeStateRef}) => (
              <SlideItem
                item={item}
                dimension={dimension}
                swipeStateRef={swipeStateRef}
              />
            )}
            renderDots={({currIdx, goToIndex}) => (
              <Dots
                mobile={mobile}
                currIdx={currIdx}
                goToIndex={goToIndex}
                dataLength={
                  (landing.type3_layout.hero_banner_section.images || []).length
                }
              />
            )}
          />
        )}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > .container {
    overflow: hidden;
    display: flex;
    justify-content: center;
  }
`;

const ActionButton = styled.button`
  border: 1px solid #ffffff;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  cursor: pointer;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;

  position: absolute;
  top: calc(50% - 16px);
  ${(props) => (props.direction === 'left' ? 'left: 20px' : 'right: 20px')}
`;

function SlideItem(props) {
  const {item, dimension, swipeStateRef} = props;
  const {width, height} = dimension;
  return (
    <SlideWrapper
      width={width}
      height={height}
      href={item.path}
      draggable={false}
      rel="noreferrer"
      target="_blank">
      <img
        src={item.image}
        draggable={false}
        alt="slide-item"
        onClick={(e) => {
          if (swipeStateRef.current === SWIPE_STATE.swipe) {
            e.preventDefault();
            return;
          }

          if (!item.path) {
            e.preventDefault();
            return;
          }

          if (item.path[0] === '/') {
            e.preventDefault();
            AppActions.navigate(item.path);
          }
        }}
      />
    </SlideWrapper>
  );
}

const SlideWrapper = styled.a`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  position: relative;
  display: block;

  & > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    user-select: none;
  }
`;

function Dots(props) {
  const {currIdx, goToIndex, dataLength, mobile} = props;

  return (
    <DotsWrapper mobile={mobile}>
      {Array.from(Array(dataLength).keys()).map((_, idx) => (
        <Dot
          key={idx}
          selected={idx === currIdx}
          onClick={() => goToIndex(idx)}
        />
      ))}
    </DotsWrapper>
  );
}

const DotsWrapper = styled.div`
  ${(props) =>
    props.mobile
      ? ` position: absolute;
          left: 0px;
          bottom: 0px;
          width: 100%;
        `
      : ` position: absolute;
          right: 80px;
          bottom: 0;
      `};

  display: flex;
  justify-content: center;
  margin: 10px 0px;
`;

const Dot = styled.div`
  margin: 10px 6px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px 0px;
  cursor: pointer;
  background-color: ${(props) =>
    props.selected ? 'var(--primaryColor)' : '#ffffff'};
`;

export default HeroBannerSection;
