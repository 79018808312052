import React from 'react';
import styled from 'styled-components';

function BeltSection(props) {
  const {landing} = props;

  return (
    <Wrapper
      className="belt-section"
      image={landing.type1_layout.belt_section.image || ''}
    />
  );
}

const Wrapper = styled.div`
  max-width: 100%;
  width: 100%;
  height: 300px;
  background-image: url(${(props) => props.image || ''});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

export default BeltSection;
