import React from 'react';
import styled from 'styled-components';
import * as AppActions from '../../../AppActions';

function BottomPromotionSection(props) {
  const {landing} = props;

  return (
    <Wrapper className="bottom-promotion-section">
      <div className="container">
        <div className="content-container">
          <div className="items-container">
            {(
              landing.type1_layout.bottom_promotion_section.images || [
                0,
                1,
                2,
                3,
              ]
            )
              .filter((_, idx) => idx <= 1)
              .map((item, idx) => (
                <BottomPromotionItem
                  key={`front-${idx}`}
                  onClick={() => AppActions.navigate(item.path || '/')}>
                  <div className="frame">
                    <img alt="bottom-promotion-img" src={item.image} />
                  </div>
                </BottomPromotionItem>
              ))}
          </div>

          <div className="items-container">
            {(
              landing.type1_layout.bottom_promotion_section.images || [
                0,
                1,
                2,
                3,
              ]
            )
              .filter((_, idx) => idx > 1)
              .map((item, idx) => (
                <BottomPromotionItem
                  key={`back-${idx}`}
                  onClick={() => AppActions.navigate(item.path || '/')}>
                  <div className="frame">
                    <img alt="bottom-promotion-img" src={item.image} />
                  </div>
                </BottomPromotionItem>
              ))}
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: 40px;
  padding-bottom: 40px;

  & > .container {
    margin: 0 auto;
    max-width: var(--contentMaxWidth);
    width: 100%;

    & > .content-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    & > .content-container > .items-container {
      flex: 1;
      display: flex;
      justify-content: space-around;
    }

    // 4 row items change 2 row items breakpoint
    @media screen and (max-width: ${440 - 1}px) {
      & > .content-container {
        flex-direction: column;
        align-items: stretch;
      }
    }
  }
`;

const BottomPromotionItem = styled.div`
  margin: 10px;
  border-radius: 15px;
  max-width: 270px;
  width: 100%;
  height: 135px;
  cursor: pointer;

  & > div.frame {
    & > img {
      width: 270px;
      height: 135px;
    }
  }

  &:hover {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
  }

  // (270+20)*4 = 1160
  @media screen and (max-width: ${1160 - 1}px) {
    max-width: 240px;
    height: 120px;

    & > div.frame {
      & > img {
        width: 240px;
        height: 120px;
      }
    }
  }

  // (240+20)*4 = 1040
  @media screen and (max-width: ${1040 - 1}px) {
    max-width: 210px;
    height: 105px;

    & > div.frame {
      & > img {
        width: 210px;
        height: 105px;
      }
    }
  }

  // (210+20)*4 = 920
  @media screen and (max-width: ${920 - 1}px) {
    max-width: 180px;
    height: 90px;

    & > div.frame {
      & > img {
        width: 180px;
        height: 90px;
      }
    }
  }

  // (180+20)*4 = 800
  @media screen and (max-width: ${800 - 1}px) {
    max-width: 150px;
    height: 75px;

    & > div.frame {
      & > img {
        width: 150px;
        height: 75px;
      }
    }
  }

  // (150+20)*4 = 680
  @media screen and (max-width: ${680 - 1}px) {
    max-width: 120px;
    height: 60px;

    & > div.frame {
      & > img {
        width: 120px;
        height: 60px;
      }
    }
  }

  // (120+20)*4 = 560
  @media screen and (max-width: ${560 - 1}px) {
    max-width: 100px;
    height: 50px;

    & > div.frame {
      & > img {
        width: 100px;
        height: 50px;
      }
    }
  }

  // (90+20)*4 = 440
  @media screen and (max-width: ${440 - 1}px) {
    max-width: 150px;
    height: 75px;

    & > div.frame {
      & > img {
        width: 150px;
        height: 75px;
      }
    }
  }
`;

export default BottomPromotionSection;
