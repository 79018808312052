import React from 'react';
import styled from 'styled-components';
import * as AppActions from '../../../AppActions';

function TopPromotionSection(props) {
  const {landing} = props;

  return (
    <Wrapper className="top-promotion-section">
      <div className="container">
        <div className="title-container">
          <h2 className="title">
            {landing.type1_layout.top_promotion_section.title}
          </h2>
          <h2 className="subtitle">
            {landing.type1_layout.top_promotion_section.subtitle}
          </h2>
        </div>
        <div className="content-container">
          {(landing.type1_layout.top_promotion_section.images || [0, 1, 2]).map(
            (item, index) => (
              <TopPromotionItem
                key={index}
                onClick={() => AppActions.navigate(item.path || '/')}>
                <div className="frame">
                  <img alt="top-promotion-img" src={item.image} />
                </div>
              </TopPromotionItem>
            ),
          )}
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f7f7f7;

  & > .container {
    margin: 0 auto;
    max-width: var(--contentMaxWidth);
    width: 100%;

    & > .content-container {
      display: flex;
      justify-content: center;
    }

    // 3 row items breakpoint
    @media screen and (max-width: ${420 - 1}px) {
      & > .content-container {
        flex-direction: column;
        align-items: center;
      }
    }
  }
`;

const TopPromotionItem = styled.div`
  margin: 10px;
  width: 270px;
  height: 270px;
  border-radius: 15px;
  cursor: pointer;

  & > div.frame {
    & > img {
      width: 270px;
      height: 270px;
    }
  }

  &:hover {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
  }

  // (270+20)*3 = 870
  @media screen and (max-width: ${870 - 1}px) {
    width: 240px;
    height: 240px;

    & > div.frame {
      & > img {
        width: 240px;
        height: 240px;
      }
    }
  }

  // (240+20)*3 = 780
  @media screen and (max-width: ${780 - 1}px) {
    width: 210px;
    height: 210px;

    & > div.frame {
      & > img {
        width: 210px;
        height: 210px;
      }
    }
  }

  // (210+20)*3 = 690
  @media screen and (max-width: ${690 - 1}px) {
    width: 180px;
    height: 180px;

    & > div.frame {
      & > img {
        width: 180px;
        height: 180px;
      }
    }
  }

  // (180+20)*3 = 600
  @media screen and (max-width: ${600 - 1}px) {
    width: 150px;
    height: 150px;

    & > div.frame {
      & > img {
        width: 150px;
        height: 150px;
      }
    }
  }

  // (150+20)*3 = 510
  @media screen and (max-width: ${510 - 1}px) {
    width: 120px;
    height: 120px;

    & > div.frame {
      & > img {
        width: 120px;
        height: 120px;
      }
    }
  }

  // (120+20)*3 = 420
  @media screen and (max-width: ${420 - 1}px) {
    width: 300px;
    height: 300px;

    & > div.frame {
      & > img {
        width: 300px;
        height: 300px;
      }
    }
  }
`;

export default TopPromotionSection;
