import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import {Button} from 'antd';
import moment from 'moment';
import {navigate} from 'gatsby';
import * as S from './Styled';

function Blogs(props) {
  const {blogs} = props;
  const [dimension] = useOutlet('dimension');
  const mobile = dimension.rwd === 'mobile';

  return (
    <Wrapper mobile={mobile}>
      <S.RowHeader>
        <div className="title">最新消息</div>
        <div className="subtitle">News & Blogs</div>
      </S.RowHeader>
      <section>
        {blogs.slice(0, 3).map((data, i) => (
          <BlogItem key={i} data={data} />
        ))}
      </section>
      <Button
        style={{textAlign: 'center'}}
        onClick={async () => {
          await navigate('/articles?cat=名人推薦');
        }}
        mobile={mobile}>
        <span>More</span>
      </Button>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: 0 auto;
  padding: 45px 45px;
  max-width: var(--contentMaxWidth);
  background-image: url('');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  text-align: center;
  & > section {
    text-align: start;
  }
`;

export default Blogs;

function BlogItem(props) {
  const [dimension] = useOutlet('dimension');
  const mobile = dimension.rwd === 'mobile';
  let {data} = props;
  return (
    <BlogWrapper
      img={data.image}
      onClick={async () => {
        await navigate(`/article?id=${data.id}`);
      }}>
      {/* <div className="bg-img" /> */}
      <img
        src={data.image || '../../images/empty-img.png'}
        alt="article"
        style={{objectFit: 'contain', maxWidth: mobile ? '100%' : '30%'}}
      />
      <div className="content">
        <div className="title">
          <h3>{data.title}</h3>
          <span>{moment(data.created).format('YYYY-MM-DD')}</span>
        </div>
        <div className="content">{data.outline}</div>
      </div>
    </BlogWrapper>
  );
}

const BlogWrapper = styled.div`
  margin-bottom: 56px;
  /* border-radius: 20px; */
  max-width: 1300px;
  width: 100%;
  min-height: 285px;
  background-color: #ffffff;
  /* box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  overflow: hidden; */
  cursor: pointer;

  display: flex;

  & > img {
    transition: 300ms;
    /* box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05), 0 6px 6px rgba(0, 0, 0, 0.05); */

    :hover {
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05), 0 6px 6px rgba(0, 0, 0, 0.05);
    }
  }
  & > .bg-img {
    max-width: 285px;
    width: 100%;
    /* background-color: #acacac; */

    background-image: url(${(props) => props.img});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  & > .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 40px 60px;

    & > .title {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 70px;

      border-bottom: 1px solid var(--primaryColor);
      padding-bottom: 5px;
      width: 100%;
      color: var(--primaryColor);

      & > h3 {
        font-size: ${(props) => (props.mobile ? '14px' : '18px')};
        color: var(--primaryColor);
        font-weight: 500;
        line-height: 24px;
        flex: 1;
      }

      & > span {
        margin-top: 15px;
        font-size: ${(props) => (props.mobile ? '12px' : '14px')};
        color: #adadad;
        align-self: flex-end;
        letter-spacing: 0px;
        font-weight: normal;
      }
    }

    & > .content {
      margin: 20px 0px 10px 0px;
      max-width: 1015px;
      width: 100%;
      height: 92px;
      line-height: 1.9;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 400;
      font-size: 16px;
      color: #4d4d4d;
    }

    & > button {
      align-self: flex-end;
      border: 0px;
      background-color: #ffffff;
      font-size: 14px;
      color: var(--primaryColor);
      cursor: pointer;

      :hover {
        border-radius: 10px;
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;

    & > .bg-img {
      max-width: none;
      height: 200px;
    }

    & > .content {
      padding: 0px;
      padding-top: 20px;

      & > .title {
        & > h3 {
          font-size: 18px;
        }
      }

      & > .content {
        height: 86px;
        font-size: 14px;
      }
    }
  }
`;
