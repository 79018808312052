import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';

import ProductItem from './ProductItem';

function ProductsSection(props) {
  const {landing, products} = props;
  const [dimension] = useOutlet('dimension');

  return (
    <Wrapper mobile={dimension.rwd === 'mobile'}>
      <div className="title-container">
        <h2 className="title">{landing.type2_layout.product_section.title}</h2>
        <h2 className="subtitle">
          {landing.type2_layout.product_section.subtitle}
        </h2>
      </div>
      <div className="container">
        {(products || []).map((product, index) => (
          <ProductItem key={index} product={product} />
        ))}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > .container {
    margin: 0 auto;
    max-width: var(--contentMaxWidth);
    width: 100%;

    display: flex;
    flex-wrap: wrap;
    justify-content: ${(props) =>
      props.mobile ? 'space-around' : 'space-between'};

    & > .content-container {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
    }

    // 3 row items breakpoint
    @media screen and (max-width: ${420 - 1}px) {
      & > .content-container {
        flex-direction: column;
        align-items: center;
      }
    }
  }
`;

export default ProductsSection;
