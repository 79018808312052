import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import {mapLineBreak} from '../../../Utils/TextUtil';
import * as AppActions from '../../../AppActions';

function CategoriesSection(props) {
  const [categories] = useOutlet('categories');
  const [articleCategories] = useOutlet('articleCategories');

  const articleCat = React.useMemo(
    () => (Array.isArray(articleCategories) ? articleCategories[0] : {}),
    [articleCategories],
  );
  const productCats = React.useMemo(
    () =>
      Array.isArray(categories) ? categories.filter((_, idx) => idx < 2) : {},
    [categories],
  );

  return (
    <Wrapper>
      <div className="row">
        <div
          className="large-rectangle"
          onClick={() =>
            AppActions.navigate(`/articles?cat=${articleCat.name}`)
          }>
          <BgImg
            src={
              articleCat.img_url || articleCategories?.meta_data.img_url || ''
            }>
            <div />
            <label>{articleCat.name}</label>
          </BgImg>
        </div>
        <div className="column">
          {productCats.map((cat, index) => (
            <div
              className="small-rectangle"
              key={index}
              onClick={() => AppActions.navigate(`/products?cat=${cat.name}`)}>
              <BgImg src={cat.img_url || categories?.meta_data.img_url || ''}>
                <div />
                <label>{cat.name}</label>
              </BgImg>
            </div>
          ))}
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  max-width: var(--contentMaxWidth);
  width: 100%;
  margin: 30px auto;

  & .row {
    margin: 0px 10px;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 960px) {
      flex-wrap: wrap;
    }
  }

  & .column {
    margin: 20px 0px 20px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 960px) {
      margin: 20px 10px;
      width: 100%;
    }
  }

  & .large-rectangle {
    cursor: pointer;
    position: relative;
    margin: 20px 10px;
    flex: 1 1 600px;
    height: 320px;
  }

  & .small-rectangle {
    cursor: pointer;
    position: relative;
    margin: 10px 0px;
    width: 300px;
    height: 150px;

    &:first-child {
      margin-top: 0px;
    }

    &:last-child {
      margin-bottom: 0px;
    }

    @media screen and (max-width: 960px) {
      margin: 10px 0px;
      width: 100%;
      height: 320px;
    }
  }
`;

const BgImg = styled.div`
  width: 100%;
  height: 100%;

  & > div {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-image: url(${(props) => props.src});
  }

  & > label {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 1.2rem;
    color: #ffffff;
    text-shadow: black 0.1em 0.1em 0.2em;
  }
`;

export default CategoriesSection;
