import React, {useEffect, useRef, useState} from 'react';
import {navigate} from 'gatsby';
import {useOutlet} from 'reconnect.js';
import styled from 'styled-components';
import {ChevronRight} from '@styled-icons/material/ChevronRight';
import {ChevronLeft} from '@styled-icons/material/ChevronLeft';

export const PrevArrow = (props) => {
  const {style = {}, onClick} = props;
  return (
    <StyledButton onClick={onClick} style={style} className="circle left">
      <ChevronLeft />
    </StyledButton>
  );
};

export const NextArrow = (props) => {
  const {style = {}, onClick} = props;
  return (
    <StyledButton onClick={onClick} style={style} className="circle right">
      <ChevronRight />
    </StyledButton>
  );
};

const StyledButton = styled.button`
  cursor: pointer;
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255);
  border-radius: 50%;
  border: none;
  box-shadow: 0 0 10px rgba(20, 20, 20, 0.1);
  position: absolute;
  top: 50%;
  z-index: 1;
  transform: translateY(-50%);
  transition: all 100ms linear;
  opacity: 0.7;

  & > svg {
    fill: green;
    font-weight: bold;
  }

  &:hover {
    transform: translateY(-50%) scale(1.1);
    opacity: 1;
  }

  &:active {
    transform: translateY(-50%) scale(1.1);
    opacity: 1;
  }
`;

const StyledScrollSection = styled.div`
  position: relative;

  & > .list-container {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    max-width: calc(100vw - 45px);

    & > .cat-item {
      min-width: ${(props) => (props.isMobile ? '186px' : '248px')};
      height: ${(props) => (props.isMobile ? '300px' : '400px')};
      background-color: #fff;
      margin-right: 30px;
      border-radius: 20px;
      position: relative;
      cursor: pointer;

      & > img {
        width: 100%;
        height: 100%;
        border-radius: 20px;
        object-fit: cover;
        transition: 200ms;

        &:hover {
          opacity: 0.7;
        }
      }
    }

    ::-webkit-scrollbar {
      display: none;
    }
  }

  & button.circle.left {
    left: 20px;
  }

  & button.circle.right {
    right: 20px;
  }
`;

function ScrollSection(props) {
  const {list = [], style = {}} = props;
  const scrollingRef = useRef(null);
  const [dimension] = useOutlet('dimension');
  const listRef = useRef([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const mobile = dimension.rwd === 'mobile';

  useEffect(() => {
    const item = listRef.current[currentIndex];
    const node = scrollingRef.current;
    node.scroll(item ? item.offsetLeft - 200 : 0, 0);
  }, [currentIndex]);

  return (
    <StyledScrollSection style={style} isMobile={mobile}>
      {currentIndex > 0 && (
        <PrevArrow
          onClick={() => {
            setCurrentIndex((pre) => pre - 1);
          }}
        />
      )}
      {currentIndex < list.length - 1 && (
        <NextArrow
          onClick={() => {
            setCurrentIndex((pre) => pre + 1);
          }}
        />
      )}

      <div ref={scrollingRef} className="list-container">
        {list.map((f, idx) => {
          return (
            <div
              className="cat-item"
              ref={(node) => {
                listRef.current[idx] = node;
              }}
              key={idx}
              onClick={async () => {
                await navigate(`/products?cat=${f.name}`);
              }}>
              <img src={`../../images/${f.display}.jpg`} alt="cat" />
              <div
                style={{
                  borderRadius: 10,
                  padding: 6,
                  background: '#a3a3a3',
                  position: 'absolute',
                  right: 0,
                  bottom: 0,
                  fontSize: 18,
                  fontWeight: 300,
                  color: '#fff',
                  letterSpacing: 2,
                }}>
                {f.display}
              </div>
            </div>
          );
        })}
      </div>
    </StyledScrollSection>
  );
}

export default ScrollSection;
