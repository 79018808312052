import React from 'react';
import styled from 'styled-components';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../../AppActions';

function ArticleSection(props) {
  const {landing, articles} = props;

  return (
    <Wrapper>
      <div className="container">
        <div className="title-container">
          <h2 className="title">
            {landing.type2_layout.article_section.title}
          </h2>
          <h2 className="subtitle">
            {landing.type2_layout.article_section.subtitle}
          </h2>
        </div>
        <div className="content-container">
          {(articles || []).map((article, index) => (
            <ArticleItem
              key={index}
              onClick={() => AppActions.navigate(`/article?id=${article.id}`)}>
              <div className="frame">
                <img alt="article-img" src={article.image} />
              </div>
              <div className="info">
                <p>
                  {new Date(article.created)
                    .toLocaleDateString()
                    .replace(/\//g, '-')}
                </p>
                <h3>{article.title}</h3>
              </div>
            </ArticleItem>
          ))}
          {[0, 1, 2].map((_, index) => (
            <ArticleItem key={`fake-${index}`} extraStyle="height: 0px;" />
          ))}
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: 40px;
  padding-bottom: 40px;

  & > .container {
    margin: 0 auto;
    max-width: var(--contentMaxWidth);
    width: 100%;

    & > .content-container {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
    }

    // 3 row items breakpoint
    @media screen and (max-width: ${420 - 1}px) {
      & > .content-container {
        flex-direction: column;
        align-items: center;
      }
    }
  }
`;

const ArticleItem = styled.div`
  margin: 10px;
  max-width: 380px;
  width: 100%;
  cursor: pointer;
  & > div.frame {
    overflow: hidden;
    border-radius: 15px;
    & > img {
      width: 380px;
      height: 252px;
      object-fit: cover;
      transition: all 200ms;
      &:hover {
        transform: scale(1.1);
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
      }
    }
  }
  & > div.info {
    padding: 10px;
    & > p {
      color: #aaa;
      font-size: 0.8rem;
    }
    & > h3 {
      font-size: 1rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
  }
  @media screen and (max-width: ${1200 - 1}px) {
    max-width: 330px;
    & > div.frame {
      & > img {
        width: 330px;
        height: 220px;
      }
    }
  }
  // (330+20)*3 = 1050
  @media screen and (max-width: ${1050 - 1}px) {
    max-width: 300px;
    & > div.frame {
      & > img {
        width: 300px;
        height: 200px;
      }
    }
  }
  // (300+20)*3 = 960
  @media screen and (max-width: ${960 - 1}px) {
    max-width: 270px;
    & > div.frame {
      & > img {
        width: 270px;
        height: 180px;
      }
    }
  }
  // (270+20)*3 = 870
  @media screen and (max-width: ${870 - 1}px) {
    max-width: 240px;
    & > div.frame {
      & > img {
        width: 240px;
        height: 160px;
      }
    }
  }
  // (240+20)*3 = 780
  @media screen and (max-width: ${780 - 1}px) {
    max-width: 210px;
    & > div.frame {
      & > img {
        width: 210px;
        height: 140px;
      }
    }
  }
  // (210+20)*3 = 690
  @media screen and (max-width: ${690 - 1}px) {
    max-width: 180px;
    & > div.frame {
      & > img {
        width: 180px;
        height: 120px;
      }
    }
  }
  // (180+20)*3 = 600
  @media screen and (max-width: ${600 - 1}px) {
    max-width: 150px;
    & > div.frame {
      & > img {
        width: 150px;
        height: 100px;
      }
    }
  }
  // (150+20)*3 = 510
  @media screen and (max-width: ${510 - 1}px) {
    max-width: 120px;
    & > div.frame {
      & > img {
        width: 120px;
        height: 80px;
      }
    }
  }
  // (120+20)*3 = 420
  @media screen and (max-width: ${420 - 1}px) {
    max-width: 300px;
    & > div.frame {
      & > img {
        width: 300px;
        height: 200px;
      }
    }
  }

  ${(props) => props.extraStyle || ''}
`;

export default ArticleSection;
