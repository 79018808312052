import React from 'react';
import {useOutlet} from 'reconnect.js';
import withPageEntry from '../../withPageEntry';
import Type1 from './type1';
import Type2 from './type2';
import Type3 from './type3';
import Custom from './custom';

function Landing(props) {
  const [_landing] = useOutlet('landing');
  const {
    pageContext: {prebuiltSiteConfig},
  } = props;

  const landing = _landing || prebuiltSiteConfig.landing || {};

  if (landing.ui_type === 'custom') {
    return <Custom {...props} landing={landing} />;
  } else if (landing.ui_type === 'type1') {
    return <Type1 {...props} landing={landing} />;
  } else if (landing.ui_type === 'type2') {
    return <Type2 {...props} landing={landing} />;
  } else if (landing.ui_type === 'type3') {
    return <Type3 {...props} landing={landing} />;
  }

  return <Custom {...props} landing={landing} />;
}

export default withPageEntry(Landing);
