import React from 'react';
import styled from 'styled-components';
import * as AppActions from '../../../AppActions';

function ProductItem(props) {
  const {product, extraCss} = props;
  return (
    <Wrapper
      css={extraCss}
      src={product.images[0]?.expected_url}
      onClick={() => AppActions.navigate(`/product/?id=${product.id}`)}>
      <div className="bgimg" />
      <div className="transition">
        <div className="name">{product.name}</div>
        <div className="bgcolor" />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  cursor: pointer;
  margin: 25px 10px 45px 10px;
  max-width: 300px;
  width: 100%;
  height: 300px;

  & > div.bgimg {
    width: 100%;
    height: 100%;
    background-image: url(${(props) => props.src});
    background-repeat: no-repeat;
    background-size: cover;
    bckkground-position: center;
  }

  & > div > div.name {
    padding: 10px;
    text-align: center;
    color: #000000;
    font-weight: 500;
    font-size: 1rem;
    width: 100%;
    position: relative;
  }

  & > div.transition {
    position: relative;

    & > div.bgcolor {
      position: absolute;
      bottom: 0px;
      right: 0px;
      left: 0px;
      height: 0px;
      background-color: rgba(211, 211, 211, 0.4);
      transition: all 0.2s ease 0s;
    }

    &:hover {
      & > div.bgcolor {
        height: 100%;
      }
    }
  }

  ${(props) => props.extraCss || ''}
`;

export default ProductItem;
