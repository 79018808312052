import React, {useMemo, useState} from 'react';
import styled from 'styled-components';
import ReactDelighters from 'rev.sdk.js/Components/ReactDelighters';
import {useOutlet} from 'reconnect.js';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../../AppActions';
import SiteNavBar from '../../../Components/SiteNavBar';
import HeroBannerSection from './HeroBannerSection';
import ProductsCat from './ProductsCat';
import Blogs from './Blogs';
import AboutSection from './AboutSection';
import ButtonSection from './ButtonSection';
import TextSection from './TextSection';

function Landing(props) {
  const {landing} = props;
  const [articles, setArticles] = useState([]);

  React.useEffect(() => {
    const fetchSite = async () => {
      AppActions.setLoading(true);
      try {
        const {results} = await JStorage.fetchDocuments(
          'Article_Default',
          {
            $or: [
              {label: {$in: ['blog', 'news']}},
              {labels: {$in: [{$regex: 'blog'}]}},
            ],
          },
          ['-created'],
        );

        setArticles(results);
      } catch (e) {
        console.warn('err', e);
      }
      AppActions.setLoading(false);
    };

    fetchSite();
  }, []);

  return (
    <ReactDelighters>
      <SiteNavBar {...props} />
      <Wrapper hasNavbar={true}>
        <div className="page-container">
          <TextSection landing={landing} />
          <HeroBannerSection landing={landing} />
        </div>
        <div className="container">
          <ButtonSection landing={landing} />
        </div>
        <div className="container">
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}>
            <ProductsCat />
          </div>
          <Blogs
            blogs={articles.filter(
              (a) => a.label?.includes('blog') || a.labels?.includes('blog'),
            )}
          />
          <AboutSection />
        </div>
      </Wrapper>
    </ReactDelighters>
  );
}
const Wrapper = styled.div`
  margin-top: ${({hasNavbar}) =>
    hasNavbar ? `var(--topNavBarHeight)` : `0px`};

  & > .page-container {
    max-width: var(--pageMaxWidth);
    width: 100%;
    margin: 0 auto;
  }

  & > .container {
    max-width: var(--contentMaxWidth);
    width: 100%;
    margin-right: auto;
    margin-left: auto;

    & > section {
      position: relative;
      overflow-x: hidden;
    }
  }

  & .landing-fade {
    opacity: 0;
    transition: 350ms;
  }

  & .landing-fade.delighter.started {
    opacity: 1;
  }

  & .landing-zoom {
    opacity: 0;
    transform: scale(0);
    transition: 350ms;
  }

  & .landing-zoom.delighter.started {
    opacity: 1;
    transform: scale(1);
  }

  & .landing-slide-up {
    opacity: 0;
    transform: translateY(200px);
    transition: 200ms;
  }

  & .landing-slide-up.delighter.started {
    opacity: 1;
    transform: translateY(0px);
  }

  & .landing-slide-in-right {
    opacity: 0;
    transform: translateX(500px);
    transition: 250ms;
  }

  & .landing-slide-in-right.delighter.started {
    opacity: 1;
    transform: translateX(0px);
  }

  & .landing-slide-in-left {
    opacity: 0;
    transform: translateX(-500px);
    transition: 250ms;
  }

  & .landing-slide-in-left.delighter.started {
    opacity: 1;
    transform: translateX(0px);
  }

  & h2 {
    font-size: 32px;
  }

  & p {
    font-size: 18px;
  }
`;

export default Landing;
