import React from 'react';
import styled from 'styled-components';
import ScrollSection from './ScrollSection';
import * as S from './Styled';

function ProductsCat(props) {
  return (
    <>
      <S.RowHeader style={{paddingTop: 50}}>
        <div className="title">熱門產品</div>
        <div className="subtitle">Product</div>
      </S.RowHeader>
      <Wrapper>
        <ScrollSection
          list={[
            {
              name: '微媞商品-溫和潔淨',
              display: '溫和潔淨',
              image: '../../images/溫和潔淨.jpg',
            },
            {
              name: '微媞商品-防曬隔離',
              display: '防曬隔離',
              image: '../../images/防曬隔離.jpg',
            },
            {
              name: '微媞商品-蝸牛修護',
              display: '蝸牛修護',
              image: '../../images/蝸牛修護.jpg',
            },
            {
              name: '微媞商品-煥采調理',
              display: '煥采調理',
              image: '../../images/煥采調理.jpg',
            },
            {
              name: '微媞商品-保濕潤澤',
              display: '保濕潤澤',
              image: '../../images/保濕潤澤.jpg',
            },
            {
              name: '微媞商品-面膜滋養',
              display: '面膜滋養',
              image: '../../images/面膜滋養.jpg',
            },
            {
              name: '微媞商品-美肌纖體食品',
              display: '美肌纖體食品',
              image: '../../images/美肌纖體食品.jpg',
            },
          ]}
        />
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 0 50px 45px;
`;

const RowHeader = styled.div`
  border-top: 9px solid #0eb407;
  width: 210px;
  padding-top: 10px;
  margin-bottom: 50px;
  & > .title,
  .subtitle {
    font-family: MavenPro, sans-serif;
    font-size: 26px;
    font-weight: 500;
    line-height: 1.04;
    text-align: left;
    color: #0eb407;
    white-space: nowrap;
  }
`;

export default ProductsCat;
