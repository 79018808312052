import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import {mapLineBreak} from '../../../Utils/TextUtil';
import * as AppActions from '../../../AppActions';

function ProductCategoriesSection(props) {
  const {landing} = props;
  const [dimension] = useOutlet('dimension');
  const mobile = dimension.rwd === 'mobile';

  return (
    <Wrapper mobile={mobile}>
      {landing.type3_layout.product_category_section.categories.map(
        (item, index) => (
          <CategoryItem key={index} item={item} />
        ),
      )}
    </Wrapper>
  );
}

function CategoryItem(props) {
  const {item} = props;

  return (
    <ItemWrapper bgImg={item.image}>
      <div className="bgImg" />
      <h3>{item.title}</h3>
      <div
        className="desp text"
        dangerouslySetInnerHTML={{__html: mapLineBreak(item.description)}}
      />
      <button onClick={() => AppActions.navigate(item.path)}>Read More</button>
    </ItemWrapper>
  );
}

const Wrapper = styled.div`
  max-width: var(--contentMaxWidth);
  width: 100%;
  margin: 0 auto;

  display: flex;
  flex-direction: ${(props) => (props.mobile ? 'column' : 'row')};
  justify-content: center;
  align-items: center;
  padding: ${(props) => (props.mobile ? '50px 0px' : '100px 40px')};
`;

const ItemWrapper = styled.div`
  margin: 10px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > div.bgImg {
    margin-bottom: 20px;
    width: 260px;
    height: 167px;

    background-image: url(${(props) => props.bgImg});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @media screen and (max-width: 920px) {
      width: 240px;
      height: 154px;
    }

    @media screen and (max-width: 860px) {
      width: 220px;
      height: 140px;
    }

    @media screen and (max-width: 800px) {
      width: 200px;
      height: 128px;
    }

    @media screen and (max-width: 768px) {
      width: 100vw;
      height: calc(100vw / 1.56);
    }
  }

  & > h3 {
    margin-bottom: 20px;
    font-size: 1.2rem;
  }

  & > div.desp {
    min-height: 44px;
    text-align: center;
  }

  & > div.text {
    margin-bottom: 40px;
    font-size: 0.85rem;
  }

  & > button {
    cursor: pointer;
    border: 0px;
    width: 200px;
    height: 40px;

    background-color: var(--primaryColor);
    color: #ffffff;

    &:hover {
      border: 1px solid var(--primaryColor);
      background-color: #ffffff;
      color: var(--primaryColor);
    }
  }
`;

export default ProductCategoriesSection;
