import React from 'react';
import styled from 'styled-components';

function TextSection(props) {
  const {landing} = props;

  return landing.custom_layout ? (
    <Wrapper>
      <div className="container">{landing.custom_layout.news}</div>
    </Wrapper>
  ) : null;
}

const Wrapper = styled.div`
  padding: 8px;
  background-color: var(--primaryColor);

  & > .container {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;

    text-align: center;
    color: #ffffff;
  }
`;

export default TextSection;
