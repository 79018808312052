import styled from 'styled-components';

const RowHeader = styled.div`
  /* border-top: 9px solid #0eb407;
  width: 210px;
  padding-top: 10px; */
  margin-bottom: 30px;
  & > .title {
    font-family: MavenPro, sans-serif;
    font-size: 22px;
    font-weight: 400;
    line-height: 1.04;
    text-align: center;
    color: #000000;
    white-space: nowrap;

    @media only screen and (max-width: 768px) {
      font-size: 18px;
    }
  }
  & > .subtitle {
    font-family: MavenPro, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.6;
    text-align: center;
    color: var(--primaryColor);
    white-space: nowrap;
  }

  @media only screen and (max-width: 768px) {
    font-size: 10px;
  }
`;

export {RowHeader};
