import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import {mapLineBreak} from '../../../Utils/TextUtil';
import * as AppActions from '../../../AppActions';

function ArticleCategoriesSection(props) {
  const {landing} = props;
  const [dimension] = useOutlet('dimension');
  const mobile = dimension.rwd === 'mobile';

  return (
    <Wrapper mobile={mobile}>
      {landing.type3_layout.article_category_section.categories.map(
        (item, index) => (
          <CategoryItem key={index} item={item} />
        ),
      )}
    </Wrapper>
  );
}

function CategoryItem(props) {
  const {item} = props;

  return (
    <ItemWrapper
      bgImg={item.image}
      onClick={() => AppActions.navigate(item.path)}>
      <div className="bgImg" />
    </ItemWrapper>
  );
}

const Wrapper = styled.div`
  max-width: var(--contentMaxWidth);
  width: 100%;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${(props) =>
    props.mobile ? '50px 0px 25px 0px' : '100px 40px 50px 40px'};
`;

const ItemWrapper = styled.div`
  cursor: pointer;
  margin-bottom: 50px;
  max-width: 820px;
  width: 100%;

  & > div.bgImg {
    margin-bottom: 10px;
    width: 100%;
    height: ${(props) =>
      props.mobile ? `calc(100vw / 3)` : `calc(820px / 3)`};

    background-image: url(${(props) => props.bgImg});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
`;

export default ArticleCategoriesSection;
