import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';

const ITEMS = [
  {
    icon: 'home-button-icon01.png',
    label: '滿$3000免運',
    path: '/about#consult',
  },
  {
    icon: 'home-button-icon02.png',
    label: '＠V-CARE加好友折$50',
    path: 'https://line.me/R/ti/p/@v-care',
  },
  {
    icon: 'home-button-icon03.png',
    label: '會員獨享優惠',
    path: '/article/?id=625fcffc7e3f6762bbcc289e',
  },
];

function ButtonSection(props) {
  const [dimension] = useOutlet('dimension');
  const {landing} = props;
  const mobile = dimension.rwd === 'mobile';
  const section = landing.custom_layout?.top_promotion_section;

  return (
    <Wrapper mobile={mobile}>
      {ITEMS.map((item, index) => (
        <ItemWrapper mobile={mobile}>
          <a target="_blank" href={item.path} rel="noreferrer">
            <div>
              <img src={`/images/${item.icon}`} alt={`item-${index}`} />
              <label>{section?.buttons[index].display || item.label}</label>
            </div>
          </a>
        </ItemWrapper>
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: 60px 15px 30px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ItemWrapper = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: #f0f0f0;
  max-width: 300px;
  width: 100%;
  height: ${(props) => (props.mobile ? '155' : '140')}px;
  margin: 5px;
  padding: ${(props) => (props.mobile ? '10' : '15')}px;
  cursor: pointer;

  & div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > img {
      max-width: 100px;
      width: 100%;
    }

    & > label {
      margin-top: 10px;
      text-align: center;
      font-size: ${(props) => (props.mobile ? '12' : '16')}px;
      font-weight: 500;
      color: #4e4e4e;
    }
  }
`;

export default ButtonSection;
