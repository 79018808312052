import React from 'react';
import styled from 'styled-components';
import HeroBannerSection from './HeroBannerSection';
import ProductCategroiesSection from './ProductCatgoriesSection';
import AboutSection from './AboutSection';
import ArticleCategoriesSection from './ArticleCatgoriesSection';

function Landing(props) {
  const {landing} = props;

  return (
    <Wrapper>
      <HeroBannerSection landing={landing} />
      <ProductCategroiesSection landing={landing} />
      <AboutSection landing={landing} />
      <ArticleCategoriesSection landing={landing} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-bottom: 80px;
  padding-top: var(--topNavBarHeight);
`;

export default Landing;
